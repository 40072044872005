var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("ProjectHeader", {
        attrs: { project: _vm.project, userInfo: _vm.userInfo },
      }),
      _c("br"),
      _c("ProjectConceptGeneral", { attrs: { project: _vm.project } }),
      _c("br"),
      _vm.project.overall_final == 0
        ? _c("ProjectObservations", { attrs: { project: _vm.project } })
        : _vm._e(),
      _c("br"),
      _vm.project.observation_restrictions.length > 0
        ? _c("ProjectRestrictions", { attrs: { project: _vm.project } })
        : _vm._e(),
      _c("br"),
      _c(
        "vx-card",
        [
          _vm.project.project_type_name === "Prueba de poligrafía" &&
          _vm.project.polygraph_attachment.slice(0, 5) !== "//s3."
            ? _c(
                "vs-button",
                {
                  staticStyle: { background: "#640064" },
                  attrs: { type: "gradient", href: { url: "/project" } },
                },
                [_vm._v("\n        Ver resultado\n      ")]
              )
            : _vm._e(),
          _vm.project.project_type_name === "Prueba de poligrafía" &&
          _vm.project.polygraph_attachment.slice(0, 5) === "//s3."
            ? _c(
                "vs-button",
                {
                  staticStyle: { background: "#640064" },
                  attrs: {
                    type: "gradient",
                    href: "https:" + _vm.project.polygraph_attachment,
                    target: "_blank",
                  },
                },
                [_vm._v("\n        Descargar reporte de poligrafía\n      ")]
              )
            : _vm._e(),
          _vm.project.project_type_name !== "Prueba de poligrafía"
            ? _c(
                "vs-button",
                {
                  staticStyle: { background: "#640064" },
                  attrs: { type: "gradient", href: { url: "/project" } },
                },
                [_vm._v("\n        Ver resultado\n      ")]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }