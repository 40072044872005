<!-- =========================================================================================
	File Name: TheNavbar.vue
	Description: Navbar component
	Component Name: TheNavbar
	----------------------------------------------------------------------------------------
	Item Name: Vuesax Admin - VueJS Dashboard Admin Template
	Author: Pixinvent
	Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
  <div class="relative">
    <div id="popupsProject"></div>
    <div id="popupViewNotification"></div>
    <div class="vx-navbar-wrapper" id="navBar">
      <vs-navbar
        class="vx-navbar navbar-custom"
        :color="navbarColor"
        :class="classObj"
      >
        <!-- SM - OPEN SIDEBAR BUTTON -->
        <feather-icon
          class="sm:inline-flex xl:hidden cursor-pointer mr-1"
          icon="MenuIcon"
          @click.stop="showSidebar"
        ></feather-icon>

        <template v-if="breakpoint != 'md'">
          <!-- STARRED PAGES - FIRST 10 -->
          <ul class="vx-navbar__starred-pages">
            <draggable
              v-model="starredPagesLimited"
              :group="{ name: 'pinList' }"
              class="flex cursor-move"
            >
              <li
                class="starred-page"
                v-for="page in starredPagesLimited"
                :key="page.url"
              >
                <vx-tooltip :text="page.label" position="bottom" delay=".3s">
                  <feather-icon
                    svgClasses="h-6 w-6"
                    class="p-2 cursor-pointer"
                    :icon="page.labelIcon"
                    @click="$router.push(page.url)"
                  ></feather-icon>
                </vx-tooltip>
              </li>
              <!-- <vs-tooltip color="warning" text="Tooltip default delay" v-if="viewButtonPoll">
              <vs-button color="warning" type="border" @click.prevent="fillPoll">Diligenciar encuesta de satisfacción 2020</vs-button>
            </vs-tooltip> -->
            </draggable>
          </ul>

          <!-- STARRED PAGES MORE -->
          <div
            class="vx-navbar__starred-pages--more-dropdown"
            v-if="starredPagesMore.length"
          >
            <vs-dropdown vs-custom-content vs-trigger-click>
              <feather-icon
                icon="ChevronDownIcon"
                svgClasses="h-4 w-4"
                class="cursor-pointer p-2"
              ></feather-icon>
              <vs-dropdown-menu>
                <ul class="vx-navbar__starred-pages-more--list">
                  <draggable
                    v-model="starredPagesMore"
                    :group="{ name: 'pinList' }"
                    class="cursor-move"
                  >
                    <li
                      class="starred-page--more flex items-center cursor-pointer"
                      v-for="page in starredPagesMore"
                      :key="page.url"
                      @click="$router.push(page.url)"
                    >
                      <feather-icon
                        svgClasses="h-5 w-5"
                        class="ml-2 mr-1"
                        :icon="page.labelIcon"
                      ></feather-icon>
                      <span class="px-2 pt-2 pb-1">{{ page.label }}</span>
                    </li>
                  </draggable>
                </ul>
              </vs-dropdown-menu>
            </vs-dropdown>
          </div>
        </template>

        <vs-spacer></vs-spacer>

        <!-- SEARCHBAR -->
        <!-- <div
          class="search-full-container w-full h-full absolute left-0 rounded-lg"
          :class="{ flex: showFullSearch }"
          v-show="showFullSearch"
        >
          <vx-auto-suggest
            class="w-full"
            inputClassses="w-full vs-input-no-border vs-input-no-shdow-focus no-icon-border"
            :autoFocus="showFullSearch"
            :data="navbarSearchAndPinList"
            icon="SearchIcon"
            placeholder="Search..."
            ref="navbarSearch"
            @closeSearchbar="showFullSearch = false"
            @selected="selected"
            background-overlay
          />
          <div class="absolute right-0 h-full z-50">
            <feather-icon
              icon="XIcon"
              class="px-4 cursor-pointer h-full close-search-icon"
              @click="showFullSearch = false"
            ></feather-icon>
          </div>
        </div>
        <feather-icon
          icon="SearchIcon"
          @click="showFinder"
          class="cursor-pointer navbar-fuzzy-search ml-4 mr-6 mt-1"
        ></feather-icon> -->
        <CampaignNotifications />
        
        <div class="the-navbar__user-meta flex items-center">
          <div class="text-right leading-tight hidden sm:block">
            <p class="font-semibold">
              {{ user_displayName }} ({{ role_name }})
            </p>
          </div>
          <vs-dropdown
            vs-custom-content
            vs-trigger-click
            class="cursor-pointer"
          >
            <div class="con-img ml-3">
              <img
                key="onlineImg"
                src="https://s3-us-west-2.amazonaws.com/globaltec-production/Users/profile_photos/58/original/Globalwork_2.PNG?1527516805"
                alt="user-img"
                width="40"
                height="40"
                class="rounded-full shadow-md cursor-pointer block"
              />
            </div>
            <vs-dropdown-menu class="vx-navbar-dropdown">
              <ul style="min-width: 9rem">
                <li
                  class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
                  v-if="this.userInfo != null"
                >
                  <div class="container-switch-pipeline-table">
                    <label for="" class="label-switch-pipeline-table tooltip">
                      Notificaciones
                      <span class="tooltiptext">Recibirás notificaciones en tu correo sobre los avances de los procesos que están en desarrollo</span>
                    </label>
                    <vs-switch
                      v-model="userNotifications"
                      @click.prevent="udpateNotificationsUser"
                    />                    
                  </div>
                </li>
                <li
                  class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
                  @click="changePassword = !changePassword"
                >
                  <feather-icon icon="LogOutIcon" svgClasses="w-4 h-4" />
                  <span class=""> Cambiar Contraseña</span>
                </li>
                <li
                  class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
                  @click="logout"
                >
                  <feather-icon icon="LogOutIcon" svgClasses="w-4 h-4" />
                  <span class="ml-2"> Salir</span>
                </li>
              </ul>
            </vs-dropdown-menu>
          </vs-dropdown>
        </div>

        <vs-popup
          background-color="rgba(255,255,255,.6)"
          class=""
          title="Proceso"
          :active.sync="changePassword"
        >
          <vs-row>
            <vs-col class="vx-col md:w-1/1 w-full">
              <vs-input
                label="Nueva Contraseña"
                type="password"
                ref="password"
                name="password"
                class="vx-col md:w-1/1 w-full"
                v-model="password"
                placeholder="Min 8 caracteres"
                v-validate="'min:8|required'"
              />
              <span
                class="text-danger"
                type="password"
                v-show="errors.has('password')"
                >Requerido* Min 8 caracteres</span
              >
            </vs-col>
            <vs-col class="vx-col md:w-1/1 w-full">
              <vs-input
                label="Confirmar Contraseña"
                type="password"
                name="passwordConfirm"
                v-model="passwordConfirm"
                class="vx-col md:w-1/1 w-full"
                placeholder="Min 8 caracteres"
                v-validate="'required|confirmed:password'"
              />
              <span class="text-danger" v-show="errors.has('passwordConfirm')"
                >Contraseñas diferentes</span
              >
            </vs-col>
          </vs-row>
          <div
            style="
              margin-top: 15px;
              display: flex !important;
              justify-content: right !important;
            "
            class="flex flex-wrap justify-between flex-col-reverse sm:flex-row"
          >
            <vs-button class="w-full sm:w-auto" @click="changeMyPassword"
              >Cambiar Contraseña</vs-button
            >
          </div>
        </vs-popup>
      </vs-navbar>
    </div>
  </div>
</template>

<script>
import VxAutoSuggest from "@/components/vx-auto-suggest/VxAutoSuggest.vue";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import draggable from "vuedraggable";
import api from "../../mixins/api.js";
import moment from "moment";
import "moment/locale/es"; // without this line it didn't work
moment.locale("es");
// import ProjectComponent from '../../components-globalwork/projects/Project'
// import ProjectViewNotification from '../../components-globalwork/projects/ProjectViewNotification'
// import Vue from 'vue'
import { mapGetters, mapMutations } from 'vuex'
import CampaignNotifications from '../../components-globalwork/traceability/CampaignNotifications.vue'

export default {
  name: "the-navbar",
  mixins: [api],
  props: {
    navbarColor: {
      type: String,
      default: "#fff",
    },
  },
  data() {
    return {
      userNotifications: false,
      viewButtonPoll: true,
      role_name: "",
      img: "../../../src/assets/images/logo/globaltec_isologo.jpg",
      icons: [
        "PlayIcon",
        "BellIcon",
        "CheckIcon",
        "AlertCircleIcon",
        "FrownIcon",
      ],
      colors: ["success", "warning", "danger", "danger", "warning"],
      notificationsRead: false,
      notificationRead: [],
      popupShowActive: false,
      projectShow: {},
      navbarSearchAndPinList: this.$store.state.navbarSearchAndPinList,
      searchQuery: "",
      showFullSearch: false,
      unreadNotifications: [],
      settings: {
        // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: 0.6,
      },
      autoFocusSearch: false,
      showBookmarkPagesDropdown: false,
      changePassword: false,
      password: "",
      passwordConfirm: "",
    };
  },
  watch: {
    $route() {
      if (this.showBookmarkPagesDropdown)
        this.showBookmarkPagesDropdown = false;
    },
  },
  computed: {
    ...mapGetters(["userInfo"]),
    // HELPER
    sidebarWidth() {
      return this.$store.state.sidebarWidth;
    },
    breakpoint() {
      return this.$store.state.breakpoint;
    },

    // NAVBAR STYLE
    classObj() {
      if (this.sidebarWidth == "default") return "navbar-default";
      else if (this.sidebarWidth == "reduced") return "navbar-reduced";
      else if (this.sidebarWidth) return "navbar-full";
    },

    // I18N
    getCurrentLocaleData() {
      const locale = this.$i18n.locale;
      if (locale == "en") return { flag: "us", lang: "English" };
      else if (locale == "pt") return { flag: "br", lang: "Portuguese" };
      else if (locale == "fr") return { flag: "fr", lang: "French" };
      else if (locale == "de") return { flag: "de", lang: "German" };
    },

    // BOOKMARK & SEARCH
    data() {
      return this.$store.state.navbarSearchAndPinList;
    },
    starredPages() {
      return this.$store.state.starredPages;
    },
    starredPagesLimited: {
      get() {
        var starredPages = this.starredPages.slice(0, 10);
        if (this.role_name == "Cliente") {
          starredPages.splice(1, 1);
          starredPages.splice(2, 1);
        }
        return starredPages;
      },
      set(list) {
        this.$store.dispatch("arrangeStarredPagesLimited", list);
      },
    },
    starredPagesMore: {
      get() {
        return this.starredPages.slice(10);
      },
      set(list) {
        this.$store.dispatch("arrangeStarredPagesMore", list);
      },
    },

    // CART DROPDOWN
    cartItems() {
      return this.$store.state.eCommerce.cartItems.slice().reverse();
    },

    // PROFILE
    user_displayName() {
      return (
        this.userInfo.user.first_name + " " + this.userInfo.user.last_name || ""
      );
    },
  },
  methods: {
    showFinder () {
      this.showFullSearch = true
      document.querySelector(".vx-navbar-wrapper").style['z-index'] = 41000;
    },
    async udpateNotificationsUser () {
      this.userNotifications = !this.userNotifications
      const request = await this.requestPutWithoutContent(`${this.url()}api/v1/users/${this.userInfo.user.id}/change_email_notifications?user_id=${this.userInfo.user.id}`, { user: { email_notifications: this.userNotifications} })
      if(request.status == 200) {
        const messages = request.data.messages
        if (messages == 'Record update') this.$store.commit('changeUserInfoEmailNotifications', this.userNotifications)
      }
    },
    fillPoll() {
      window.open(
        "https://docs.google.com/forms/d/e/1FAIpQLSf1imxVXv2l5l0KV2UMZFcndCqKjm_UyhTuLDc8Pyu8d3Bc1w/viewform",
        "_blank"
      );
      let vieButtonPoll = window.localStorage.setItem("viewButtonPoll", true);
      this.viewButtonPoll = false;
    },
    ...mapMutations(["resetUserInfo"]),
    activeUserImg() {
      var avatarUrl = this.userInfo.user.avatar_url;
      if (avatarUrl != null) {
        avatarUrl = avatarUrl.replace("//", "https://");
        avatarUrl = avatarUrl.replace("development", "production");
        this.img = avatarUrl;
      }
    },
    checkNotificationRead(clientNotificationId) {
      console.log(
        "Marcar como leída client notification ",
        clientNotificationId
      );
      var client_id = this.userInfo.user.client_id;
      var path =
        "/api/v1/clients/check_notification_read?client_id=" +
        client_id +
        "&client_notification_id=" +
        clientNotificationId;
      this.requestGetWithoutContent(this.url() + path, {}).then(
        (response) => {
          if (response.data.success == true) {
            this.getNotifications();
          }
        },
        (err) => {
          console.log("err users -> " + JSON.stringify(err));
        }
      );
    },
    allNotificationRead() {
      console.log("Marcar las actuales notificaciones como leídas");
      var client_id = this.userInfo.user.client_id;
      var notifications_id = this.unreadNotifications.map(
        (unreadNotification) => unreadNotification.id
      );
      var path =
        "/api/v1/clients/check_list_notification_read?client_id=" +
        client_id +
        "&notifications_id=" +
        notifications_id;
      this.requestGetWithoutContent(this.url() + path, {}).then(
        (response) => {
          if (response.data.success == true) {
            this.getNotifications();
          }
        },
        (err) => {
          console.log("err users -> " + JSON.stringify(err));
        }
      );
    },
    getNotifications() {
      var client_id = this.userInfo.user.client_id;
      var path = "/api/v1/clients/notifications?client_id=" + client_id;
      this.requestGetWithoutContent(this.url() + path, {}).then(
        (response) => {
          if (response.data.success == true) {
            this.unreadNotifications = response.data.data;
          } else {
            //console.log(response.data.message);
          }
        },
        (err) => {
          console.log("err -> " + err);
        }
      );
    },
    convertTimeStamp(timestamp) {
      var date = new Date(timestamp * 1000);
      return moment(date, "YYYYMMDD").fromNow();
    },
    updateLocale(locale) {
      this.$i18n.locale = locale;
    },
    showSidebar() {
      this.$store.commit("TOGGLE_IS_SIDEBAR_ACTIVE", true);
    },
    selected(item) {
      this.$router.push(item.url);
      this.showFullSearch = false;
    },
    actionClicked(item) {
      // e.stopPropogation();
      this.$store.dispatch("updateStarredPage", {
        index: item.index,
        val: !item.highlightAction,
      });
    },
    showNavbarSearch() {
      this.showFullSearch = true;
    },
    showSearchbar() {
      this.showFullSearch = true;
    },
    elapsedTime(startTime) {
      let x = new Date(startTime);
      let now = new Date();
      var timeDiff = now - x;
      timeDiff /= 1000;

      var seconds = Math.round(timeDiff);
      timeDiff = Math.floor(timeDiff / 60);

      var minutes = Math.round(timeDiff % 60);
      timeDiff = Math.floor(timeDiff / 60);

      var hours = Math.round(timeDiff % 24);
      timeDiff = Math.floor(timeDiff / 24);

      var days = Math.round(timeDiff % 365);
      timeDiff = Math.floor(timeDiff / 365);

      var years = timeDiff;

      if (years > 0) {
        return years + (years > 1 ? " Years " : " Year ") + "ago";
      } else if (days > 0) {
        return days + (days > 1 ? " Days " : " Day ") + "ago";
      } else if (hours > 0) {
        return hours + (hours > 1 ? " Hrs " : " Hour ") + "ago";
      } else if (minutes > 0) {
        return minutes + (minutes > 1 ? " Mins " : " Min ") + "ago";
      } else if (seconds > 0) {
        return seconds + (seconds > 1 ? `${seconds} sec ago` : "just now");
      }

      return "Just Now";
    },
    logout() {
      this.resetUserInfo();
      window.localStorage.clear();
      this.$router.push("/pages/login");
    },
    outside: function () {
      this.showBookmarkPagesDropdown = false;
    },

    // CART DROPDOWN
    removeItemFromCart(item) {
      this.$store.dispatch("eCommerce/toggleItemInCart", item);
    },

    changeMyPassword() {
      if (this.password.length >= 8 && this.passwordConfirm.length >= 8) {
        if (this.password == this.passwordConfirm) {
          this.requestPut(this.url() + "api/v1/user", {
            current_user: this.userInfo.user.id,
            user_id: this.userInfo.user.id,
            user: { id: this.userInfo.user.id, password: this.password },
          }).then((response) => {
            if (response.data.response.success) {
              this.$vs.notify({
                title: "Mensaje de Globalwork",
                text: "Contraseña actualizada correctamente",
                color: "primary",
                position: "bottom-left",
              });
              localStorage.clear();
              setTimeout(() => {
                this.changePassword = false;
                this.$router.push("/pages/login");
              }, 1000);
            }
          });
        } else if (this.password != this.passwordConfirm) {
          this.$vs.notify({
            title: "Mensaje de Globalwork",
            text: "Las contraseñas son diferentes",
            color: "primary",
            position: "bottom-left",
          });
        }
      }
    }
  },
  components: {
    VxAutoSuggest,
    VuePerfectScrollbar,
    draggable,
    CampaignNotifications
  },
  created: function () {
    this.role_name = this.userInfo.user.role_name;
    this.activeUserImg();
    this.getNotifications();
  },
  mounted() {
    let viewButtonPoll = window.localStorage.getItem("viewButtonPoll");
    if (viewButtonPoll != null) this.viewButtonPoll = false;
    if (this.userInfo != null) {
      if (this.userInfo.user.email_notifications == null) {
        this.userNotifications = false
      } else {
        this.userNotifications = this.userInfo.user.email_notifications
      }      
    } else {
      this.userNotifications = false
    }
  },
};
</script>
<style scoped>
.tooltip {
  position: relative;
  display: inline-block;
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 250px;
  background: #FAF8FF;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  top: -80px;
  right: -105%;
  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
  z-index: 4100;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
}
</style>

