var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "vx-auto-suggest" },
    [
      _c(
        "vs-popup",
        {
          attrs: {
            "background-color": "rgba(255,255,255,.6)",
            title: "Proceso",
            active: _vm.popupShowActive,
          },
          on: {
            "update:active": function ($event) {
              _vm.popupShowActive = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "vx-col w-full lg:w-1/1 sm:w-1/1 mb-base" },
            [
              _c(
                "div",
                { staticClass: "vx-card p-2" },
                [
                  _vm.projectShow
                    ? _c("ProjectInformation", {
                        attrs: { project: _vm.projectShow },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ]
          ),
        ]
      ),
      _c(
        "div",
        { staticClass: "flex items-center relative" },
        [
          _c("vs-input", {
            ref: "input",
            staticClass: "z-50",
            class: _vm.inputClassses,
            staticStyle: { "z-index": "10" },
            attrs: {
              placeholder: "Buscar proceso por cédula",
              "icon-pack": "feather",
              icon: "icon-search",
              "icon-no-border": "",
            },
            on: {
              keyup: [
                function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "esc", 27, $event.key, [
                      "Esc",
                      "Escape",
                    ])
                  ) {
                    return null
                  }
                  return _vm.escPressed.apply(null, arguments)
                },
                function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "up", 38, $event.key, [
                      "Up",
                      "ArrowUp",
                    ])
                  ) {
                    return null
                  }
                  return _vm.increaseIndex(false)
                },
                function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "down", 40, $event.key, [
                      "Down",
                      "ArrowDown",
                    ])
                  ) {
                    return null
                  }
                  return _vm.increaseIndex.apply(null, arguments)
                },
                function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  return _vm.suggestionSelected.apply(null, arguments)
                },
              ],
              focus: _vm.updateInputFocus,
              blur: function ($event) {
                return _vm.updateInputFocus(false)
              },
            },
            model: {
              value: _vm.searchQuery,
              callback: function ($$v) {
                _vm.searchQuery = $$v
              },
              expression: "searchQuery",
            },
          }),
        ],
        1
      ),
      _c(
        "ul",
        {
          ref: "scrollContainer",
          staticClass:
            "auto-suggest-suggestions-list z-50 rounded-lg mt-2 shadow-lg overflow-hidden",
          class: { hidden: !_vm.inputFocused },
          attrs: { tabindex: "-1" },
          on: {
            mouseenter: function ($event) {
              _vm.insideSuggestions = true
            },
            mouseleave: function ($event) {
              _vm.insideSuggestions = false
            },
            focus: _vm.updateInputFocus,
            blur: function ($event) {
              return _vm.updateInputFocus(false)
            },
          },
        },
        _vm._l(_vm.filteredData, function (suggestion, index) {
          return _c(
            "li",
            {
              key: index,
              ref: "option",
              refInFor: true,
              staticClass:
                "auto-suggest__suggestion flex items-center justify-between py-3 cursor-pointer",
              class: {
                "vx-auto-suggest__current-selected":
                  _vm.currentSelected == index,
                "pointer-events-none": suggestion.index < 0,
              },
              on: {
                mouseenter: function ($event) {
                  _vm.currentSelected = index
                },
                click: _vm.suggestionSelected,
              },
            },
            [
              _c("div", { staticClass: "flex items-center" }, [
                Object.keys(suggestion).length == 4
                  ? _c("span", [
                      _vm._v(
                        _vm._s(suggestion.project_id) +
                          " - " +
                          _vm._s(suggestion.full_name) +
                          ", " +
                          _vm._s(suggestion.project_type) +
                          ", " +
                          _vm._s(_vm.convertTimeStamp(suggestion.timestamp)) +
                          ", " +
                          _vm._s(suggestion.status)
                      ),
                    ])
                  : _vm._e(),
                Object.keys(suggestion).length == 5
                  ? _c("span", [_vm._v("Buscando...")])
                  : _vm._e(),
              ]),
            ]
          )
        }),
        0
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }