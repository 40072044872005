<template>
	<div class="vx-auto-suggest">
    <vs-popup background-color="rgba(255,255,255,.6)"  class=""  title="Proceso" :active.sync="popupShowActive">
      <div class="vx-col w-full lg:w-1/1 sm:w-1/1 mb-base">
        <div class="vx-card p-2">
			<ProjectInformation :project="projectShow" v-if="projectShow"/>
        </div>
      </div>
    </vs-popup>
		<div class="flex items-center relative">
			<vs-input style="z-index:10;" placeholder="Buscar proceso por cédula" ref="input" :class="inputClassses" class="z-50" icon-pack='feather' icon='icon-search' icon-no-border v-model="searchQuery" @keyup.esc="escPressed" @keyup.up="increaseIndex(false)" @keyup.down="increaseIndex" @keyup.enter="suggestionSelected" @focus="updateInputFocus" @blur="updateInputFocus(false)"></vs-input>
		</div>
		<ul ref="scrollContainer" class="auto-suggest-suggestions-list z-50 rounded-lg mt-2 shadow-lg overflow-hidden" :class="{'hidden': !inputFocused}" @mouseenter="insideSuggestions = true" @mouseleave="insideSuggestions = false" @focus="updateInputFocus" @blur="updateInputFocus(false)" tabindex="-1">
			<li
				ref="option"
				class="auto-suggest__suggestion flex items-center justify-between py-3 cursor-pointer"
				v-for="(suggestion, index) in filteredData"
				:key="index"
				:class="{'vx-auto-suggest__current-selected': currentSelected == index, 'pointer-events-none': suggestion.index < 0}"
				@mouseenter="currentSelected = index"
				@click="suggestionSelected">

				<div class="flex items-center">
					<!-- <feather-icon :icon="Starticon" svgClasses="h-5 w-5" class="mr-4"></feather-icon> -->
					<span v-if="Object.keys(suggestion).length == 4">{{ suggestion.project_id }} - {{ suggestion.full_name }}, {{ suggestion.project_type }}, {{ convertTimeStamp(suggestion.timestamp) }}, {{ suggestion.status }}</span>
					<span v-if="Object.keys(suggestion).length == 5">Buscando...</span>
				</div>
				<!-- <feather-icon v-if="showAction" :icon="data.actionIcon" :svgClasses="[actionClasses(suggestion.highlightAction), 'h-5 w-5']" @click.stop="actionClicked"></feather-icon> -->
				<!-- <feather-icon v-if="showAction" :icon="data.actionIcon" :svgClasses="[actionClasses(suggestion.highlightAction), 'h-5 w-5']" @click.stop="actionClicked"></feather-icon> -->
			</li>
		</ul>
	</div>
</template>

<script>
import moment from 'moment'
import 'moment/locale/es'  // without this line it didn't work
moment.locale('es')
import api from '../../mixins/api.js'
import ProjectInformation from '../../components-globalwork/projects/ProjectInformation.vue'
import { mapGetters, mapMutations} from 'vuex'
export default{
	mixins: [api],
	props: {
		placeholder: {
			type: String,
			default: 'Search..'
		},
		data: {
			type: Object,
			required: true,
		},
		showAction: {
			type: Boolean,
			default: false,
		},
		inputClassses: {
			type: [String, Object, Array],
		},
		autoFocus: {
			type: Boolean,
			default: false,
		},
		showPinned: {
			type: Boolean,
			default: false
		},
		backgroundOverlay: {
			type: Boolean,
			default: false
		},
		searchLimit: {
			type: Number,
			default: 10
		}
	},
	data() {
		return {
			popupShowActive: false,
			projectShow: null,
			searchQuery: '',
			filteredData: [],
			currentSelected: -1,
			inputFocused: false,
			insideSuggestions: false,
		}
	},
	watch: {
		// UPDATE SUGGESTIONS LIST
		searchQuery(val) {
			if(val.length >= 3){
				if(this.backgroundOverlay && !this.bodyOverlay) this.$store.commit('TOGGLE_CONTENT_OVERLAY', true);				
				var client_id = this.userInfo.user.client_id
				this.requestGetWithoutContent(this.url()+"api/v1/clients/search_projects?client_id="+client_id+"&query="+val, {}).then(response => {
					if(response.data.success == true){
						var data = response.data.data;
						this.filteredData = data
						if(!this.filteredData[0]) this.currentSelected = -1						
					}else{
						//console.log(response.message);
					}
				}, (err) => {
					console.log(err);
				});				
			}else{
				this.inputInit();
				if(this.bodyOverlay) this.$store.commit('TOGGLE_CONTENT_OVERLAY', false);				
			}
			// if(val == '') {
			// 	this.inputInit();
			// 	if(this.bodyOverlay) this.$store.commit('TOGGLE_CONTENT_OVERLAY', false);
			// }else {
			// 	if(this.backgroundOverlay && !this.bodyOverlay) this.$store.commit('TOGGLE_CONTENT_OVERLAY', true);
			// 	let exactEle = this.data.data.filter((item) => {
			// 		return item.label.toLowerCase().startsWith(this.searchQuery.toLowerCase())
			// 	});
			// 	let containEle = this.data.data.filter((item) => {
			// 		return !item.label.toLowerCase().startsWith(this.searchQuery.toLowerCase()) && item.label.toLowerCase().indexOf(this.searchQuery.toLowerCase()) > -1
			// 	});
			// 	this.filteredData = exactEle.concat(containEle).slice(0,this.searchLimit)
			// 	if(!this.filteredData[0]) this.currentSelected = -1
			// }

			// ADD: No result found
			if(!this.filteredData.length && this.searchQuery) {
				this.filteredData = [{
					highlightAction: false,
					index: -1,
					label: 'No se encontraron resultados',
					labelIcon: 'AlertCircleIcon',
					url: null,
				}]
			}
		},
		autoFocus(val) {
			if(val) this.focusInput();
			else this.searchQuery = '';
		},
		filteredData() {
      this.currentSelected = 0;

			// Prevent selecting if first item in list dont have url e.g. 'No Reult'
			if(this.filteredData[0]){
				if(!this.filteredData[0].url) {
					this.currentSelected = -1
				}
			}
		}
	},
	computed: {
		...mapGetters(['userInfo']),		
		bodyOverlay() {
			return this.$store.state.bodyOverlay;
		},
		actionClasses() {
			return (isHighlighted) => {
				if(isHighlighted) return `stroke-current text-${this.data.highlightColor}`
			}
		}
	},
	methods: {
		...mapMutations(['changeCurrentProject']),
		escPressed() {
			//console.log("***escPressed()***")
			this.$emit('closeSearchbar')
			this.searchQuery = '';
			this.filteredData = [];
		},
		inputInit() {
			if(this.showPinned) {
				const starredData = this.data.data.filter((item) => item.highlightAction)
				this.filteredData = starredData;
			}else {
				this.filteredData = [];
			}
		},
		updateInputFocus(val = true) {
			if(val) {
				if(this.searchQuery == '') this.inputInit();
				setTimeout(() => {
					this.inputFocused = true;
				}, 100)
			}
			else {
				if(this.insideSuggestions) return
				setTimeout(() => {
					this.inputFocused = false;
				}, 100)
				this.escPressed();
			}
		},
		suggestionSelected() {
			var project_id = this.filteredData[this.currentSelected].project_id
			this.getProject(project_id) 
			this.escPressed()
		},
		getProject(project_id){
			var user_id = this.userInfo.user.id
			var path = "/api/v1/projects/" + project_id + "?user_id="+user_id;
			var url = this.url()+path
			this.requestGetWithoutContent(url, {}).then(response => {
				if(response.data.success == true){
					this.projectShow = response.data.data
					this.changeCurrentProject(this.projectShow)
					this.popupShowActive = true;
				}else{
				//console.log("error -> " + JSON.stringify(response.data.message));          
				}
			}, (err) => {
				console.log('err users -> ' +  JSON.stringify(err))
			}) 
		},		
		actionClicked() {
			this.$emit('actionClicked', this.filteredData[this.currentSelected])
			if(!this.filteredData[this.currentSelected].highlightAction) this.filteredData.splice(this.currentSelected, 1)
		},
		increaseIndex(val = false) {
			if(!val && this.currentSelected > 0) this.currentSelected--
			else if(val && (this.currentSelected < this.filteredData.length-1) && (this.filteredData[this.currentSelected+1].index > -1)) this.currentSelected++
			this.fixScrolling();
		},
		focusInput() {
			this.$refs.input.$el.querySelector('input').focus();
		},
		fixScrolling() {
			if(this.currentSelected > 0) {
				const liH = this.$refs.option[this.currentSelected].clientHeight;
				const ulH = this.$refs.scrollContainer.clientHeight;
				if(ulH - (liH * this.currentSelected) < liH){
					this.$refs.scrollContainer.scrollTop = Math.round(((this.currentSelected+1) - (ulH / liH)+1) * liH);
				}
			}
		},
		convertTimeStamp(timestamp){
			var date = new Date(timestamp * 1000);
			return moment(date, "YYYYMMDD").fromNow();
		}		
	},
	mounted() {
		if(this.autoFocus) this.focusInput();		
	},
	components:{
		ProjectInformation
	}
}
</script>

<style lang="scss">
@import "@/assets/scss/vuesax/components/vxAutoSuggest.scss";
</style>
