var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.notifications != null
    ? _c(
        "div",
        [
          _c(
            "vs-dropdown",
            {
              staticClass: "cursor-pointer",
              attrs: { "vs-custom-content": "", "vs-trigger-click": "" },
            },
            [
              _c("feather-icon", {
                staticClass: "cursor-pointer mt-1 sm:mr-6 mr-6",
                attrs: { icon: "BellIcon", badge: _vm.notifications.length },
              }),
              _c(
                "vs-dropdown-menu",
                {
                  staticClass:
                    "notification-dropdown dropdown-custom vx-navbar-dropdown",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "notification-top text-center p-5 bg-primary text-white",
                    },
                    [
                      _c("h3", { staticClass: "text-white" }, [
                        _vm._v(
                          _vm._s(_vm.notifications.length) + " notificaciones"
                        ),
                      ]),
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "container-notifications" },
                    [
                      _vm._l(_vm.notifications, function (notification) {
                        return _c(
                          "div",
                          {
                            key: notification.project.id,
                            staticClass: "container-notification",
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.goToNotifications(
                                  notification.project.id
                                )
                              },
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "container-icon event-notification",
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "feather-icon select-none relative",
                                    attrs: { "data-v-2c2399b2": "" },
                                  },
                                  [
                                    _c(
                                      "svg",
                                      {
                                        staticClass:
                                          "feather feather-bell text-warning stroke-current mr-1 h-6 w-6",
                                        attrs: {
                                          xmlns: "http://www.w3.org/2000/svg",
                                          width: "24",
                                          height: "24",
                                          viewBox: "0 0 24 24",
                                          fill: "none",
                                          stroke: "currentColor",
                                          "stroke-width": "2",
                                          "stroke-linecap": "round",
                                          "stroke-linejoin": "round",
                                        },
                                      },
                                      [
                                        _c("path", {
                                          attrs: {
                                            d: "M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9",
                                          },
                                        }),
                                        _c("path", {
                                          attrs: {
                                            d: "M13.73 21a2 2 0 0 1-3.46 0",
                                          },
                                        }),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "notification-content event-notification",
                              },
                              [
                                _c(
                                  "small",
                                  { staticClass: "text-content-notification" },
                                  [
                                    _vm._v(
                                      _vm._s(notification.project.full_name)
                                    ),
                                  ]
                                ),
                                _c(
                                  "small",
                                  {
                                    staticClass:
                                      "name-key-content-notification",
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        notification.last_notification.name_key
                                      )
                                    ),
                                  ]
                                ),
                                _c("div", { staticClass: "notification-ago" }, [
                                  _c(
                                    "small",
                                    { staticClass: "text-ago-notification" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("formatDate")(
                                            notification.last_notification
                                              .created_at
                                          )
                                        )
                                      ),
                                    ]
                                  ),
                                ]),
                              ]
                            ),
                          ]
                        )
                      }),
                      _vm.notifications.length > 0
                        ? _c(
                            "div",
                            { staticClass: "container-button-view-more" },
                            [
                              _c(
                                "p",
                                {
                                  staticClass: "button-view-more",
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      _vm.page += 1
                                    },
                                  },
                                },
                                [_vm._v(" Ver más")]
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    2
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }