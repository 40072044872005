var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "relative" }, [
    _c("div", { attrs: { id: "popupsProject" } }),
    _c("div", { attrs: { id: "popupViewNotification" } }),
    _c(
      "div",
      { staticClass: "vx-navbar-wrapper", attrs: { id: "navBar" } },
      [
        _c(
          "vs-navbar",
          {
            staticClass: "vx-navbar navbar-custom",
            class: _vm.classObj,
            attrs: { color: _vm.navbarColor },
          },
          [
            _c("feather-icon", {
              staticClass: "sm:inline-flex xl:hidden cursor-pointer mr-1",
              attrs: { icon: "MenuIcon" },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.showSidebar.apply(null, arguments)
                },
              },
            }),
            _vm.breakpoint != "md"
              ? [
                  _c(
                    "ul",
                    { staticClass: "vx-navbar__starred-pages" },
                    [
                      _c(
                        "draggable",
                        {
                          staticClass: "flex cursor-move",
                          attrs: { group: { name: "pinList" } },
                          model: {
                            value: _vm.starredPagesLimited,
                            callback: function ($$v) {
                              _vm.starredPagesLimited = $$v
                            },
                            expression: "starredPagesLimited",
                          },
                        },
                        _vm._l(_vm.starredPagesLimited, function (page) {
                          return _c(
                            "li",
                            { key: page.url, staticClass: "starred-page" },
                            [
                              _c(
                                "vx-tooltip",
                                {
                                  attrs: {
                                    text: page.label,
                                    position: "bottom",
                                    delay: ".3s",
                                  },
                                },
                                [
                                  _c("feather-icon", {
                                    staticClass: "p-2 cursor-pointer",
                                    attrs: {
                                      svgClasses: "h-6 w-6",
                                      icon: page.labelIcon,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.$router.push(page.url)
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        }),
                        0
                      ),
                    ],
                    1
                  ),
                  _vm.starredPagesMore.length
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "vx-navbar__starred-pages--more-dropdown",
                        },
                        [
                          _c(
                            "vs-dropdown",
                            {
                              attrs: {
                                "vs-custom-content": "",
                                "vs-trigger-click": "",
                              },
                            },
                            [
                              _c("feather-icon", {
                                staticClass: "cursor-pointer p-2",
                                attrs: {
                                  icon: "ChevronDownIcon",
                                  svgClasses: "h-4 w-4",
                                },
                              }),
                              _c("vs-dropdown-menu", [
                                _c(
                                  "ul",
                                  {
                                    staticClass:
                                      "vx-navbar__starred-pages-more--list",
                                  },
                                  [
                                    _c(
                                      "draggable",
                                      {
                                        staticClass: "cursor-move",
                                        attrs: { group: { name: "pinList" } },
                                        model: {
                                          value: _vm.starredPagesMore,
                                          callback: function ($$v) {
                                            _vm.starredPagesMore = $$v
                                          },
                                          expression: "starredPagesMore",
                                        },
                                      },
                                      _vm._l(
                                        _vm.starredPagesMore,
                                        function (page) {
                                          return _c(
                                            "li",
                                            {
                                              key: page.url,
                                              staticClass:
                                                "starred-page--more flex items-center cursor-pointer",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.$router.push(
                                                    page.url
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("feather-icon", {
                                                staticClass: "ml-2 mr-1",
                                                attrs: {
                                                  svgClasses: "h-5 w-5",
                                                  icon: page.labelIcon,
                                                },
                                              }),
                                              _c(
                                                "span",
                                                {
                                                  staticClass: "px-2 pt-2 pb-1",
                                                },
                                                [_vm._v(_vm._s(page.label))]
                                              ),
                                            ],
                                            1
                                          )
                                        }
                                      ),
                                      0
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              : _vm._e(),
            _c("vs-spacer"),
            _c("CampaignNotifications"),
            _c(
              "div",
              { staticClass: "the-navbar__user-meta flex items-center" },
              [
                _c(
                  "div",
                  { staticClass: "text-right leading-tight hidden sm:block" },
                  [
                    _c("p", { staticClass: "font-semibold" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.user_displayName) +
                          " (" +
                          _vm._s(_vm.role_name) +
                          ")\n          "
                      ),
                    ]),
                  ]
                ),
                _c(
                  "vs-dropdown",
                  {
                    staticClass: "cursor-pointer",
                    attrs: { "vs-custom-content": "", "vs-trigger-click": "" },
                  },
                  [
                    _c("div", { staticClass: "con-img ml-3" }, [
                      _c("img", {
                        key: "onlineImg",
                        staticClass:
                          "rounded-full shadow-md cursor-pointer block",
                        attrs: {
                          src: "https://s3-us-west-2.amazonaws.com/globaltec-production/Users/profile_photos/58/original/Globalwork_2.PNG?1527516805",
                          alt: "user-img",
                          width: "40",
                          height: "40",
                        },
                      }),
                    ]),
                    _c(
                      "vs-dropdown-menu",
                      { staticClass: "vx-navbar-dropdown" },
                      [
                        _c("ul", { staticStyle: { "min-width": "9rem" } }, [
                          this.userInfo != null
                            ? _c(
                                "li",
                                {
                                  staticClass:
                                    "flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "container-switch-pipeline-table",
                                    },
                                    [
                                      _c(
                                        "label",
                                        {
                                          staticClass:
                                            "label-switch-pipeline-table tooltip",
                                          attrs: { for: "" },
                                        },
                                        [
                                          _vm._v(
                                            "\n                    Notificaciones\n                    "
                                          ),
                                          _c(
                                            "span",
                                            { staticClass: "tooltiptext" },
                                            [
                                              _vm._v(
                                                "Recibirás notificaciones en tu correo sobre los avances de los procesos que están en desarrollo"
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _c("vs-switch", {
                                        on: {
                                          click: function ($event) {
                                            $event.preventDefault()
                                            return _vm.udpateNotificationsUser.apply(
                                              null,
                                              arguments
                                            )
                                          },
                                        },
                                        model: {
                                          value: _vm.userNotifications,
                                          callback: function ($$v) {
                                            _vm.userNotifications = $$v
                                          },
                                          expression: "userNotifications",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _c(
                            "li",
                            {
                              staticClass:
                                "flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white",
                              on: {
                                click: function ($event) {
                                  _vm.changePassword = !_vm.changePassword
                                },
                              },
                            },
                            [
                              _c("feather-icon", {
                                attrs: {
                                  icon: "LogOutIcon",
                                  svgClasses: "w-4 h-4",
                                },
                              }),
                              _c("span", {}, [_vm._v(" Cambiar Contraseña")]),
                            ],
                            1
                          ),
                          _c(
                            "li",
                            {
                              staticClass:
                                "flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white",
                              on: { click: _vm.logout },
                            },
                            [
                              _c("feather-icon", {
                                attrs: {
                                  icon: "LogOutIcon",
                                  svgClasses: "w-4 h-4",
                                },
                              }),
                              _c("span", { staticClass: "ml-2" }, [
                                _vm._v(" Salir"),
                              ]),
                            ],
                            1
                          ),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "vs-popup",
              {
                attrs: {
                  "background-color": "rgba(255,255,255,.6)",
                  title: "Proceso",
                  active: _vm.changePassword,
                },
                on: {
                  "update:active": function ($event) {
                    _vm.changePassword = $event
                  },
                },
              },
              [
                _c(
                  "vs-row",
                  [
                    _c(
                      "vs-col",
                      { staticClass: "vx-col md:w-1/1 w-full" },
                      [
                        _c("vs-input", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "min:8|required",
                              expression: "'min:8|required'",
                            },
                          ],
                          ref: "password",
                          staticClass: "vx-col md:w-1/1 w-full",
                          attrs: {
                            label: "Nueva Contraseña",
                            type: "password",
                            name: "password",
                            placeholder: "Min 8 caracteres",
                          },
                          model: {
                            value: _vm.password,
                            callback: function ($$v) {
                              _vm.password = $$v
                            },
                            expression: "password",
                          },
                        }),
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.errors.has("password"),
                                expression: "errors.has('password')",
                              },
                            ],
                            staticClass: "text-danger",
                            attrs: { type: "password" },
                          },
                          [_vm._v("Requerido* Min 8 caracteres")]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "vs-col",
                      { staticClass: "vx-col md:w-1/1 w-full" },
                      [
                        _c("vs-input", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required|confirmed:password",
                              expression: "'required|confirmed:password'",
                            },
                          ],
                          staticClass: "vx-col md:w-1/1 w-full",
                          attrs: {
                            label: "Confirmar Contraseña",
                            type: "password",
                            name: "passwordConfirm",
                            placeholder: "Min 8 caracteres",
                          },
                          model: {
                            value: _vm.passwordConfirm,
                            callback: function ($$v) {
                              _vm.passwordConfirm = $$v
                            },
                            expression: "passwordConfirm",
                          },
                        }),
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.errors.has("passwordConfirm"),
                                expression: "errors.has('passwordConfirm')",
                              },
                            ],
                            staticClass: "text-danger",
                          },
                          [_vm._v("Contraseñas diferentes")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "flex flex-wrap justify-between flex-col-reverse sm:flex-row",
                    staticStyle: {
                      "margin-top": "15px",
                      display: "flex !important",
                      "justify-content": "right !important",
                    },
                  },
                  [
                    _c(
                      "vs-button",
                      {
                        staticClass: "w-full sm:w-auto",
                        on: { click: _vm.changeMyPassword },
                      },
                      [_vm._v("Cambiar Contraseña")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          2
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }