<template>
  <div>
    <ProjectHeader :project="project" :userInfo="userInfo"/>
    <br>
    <ProjectConceptGeneral :project="project"/>
    <br>
    <ProjectObservations :project="project" v-if="project.overall_final == 0"/>
    <br>
    <ProjectRestrictions :project="project" v-if="project.observation_restrictions.length > 0"/>
    <br>
    <vx-card>
      <vs-button
          v-if="project.project_type_name === 'Prueba de poligrafía' && project.polygraph_attachment.slice(0, 5) !== '//s3.'"
          type="gradient"
          style="background: #640064;"
          :href="{ url: '/project' }"
        >
          Ver resultado
        </vs-button>
        <vs-button
          v-if="project.project_type_name === 'Prueba de poligrafía' && project.polygraph_attachment.slice(0, 5) === '//s3.'"
          type="gradient"
          style="background: #640064;"
          :href="`https:${project.polygraph_attachment}`"
          target="_blank"
        >
          Descargar reporte de poligrafía
        </vs-button>
        <vs-button
          v-if="project.project_type_name !== 'Prueba de poligrafía'"
          type="gradient"
          style="background: #640064;"
          :href="{ url: '/project' }"
        >
          Ver resultado
        </vs-button>
    </vx-card>
  </div>
</template>

<script>
import ProjectHeader from './ProjectHeader.vue'
import ProjectConceptGeneral from './ProjectConceptGeneral.vue'
import ProjectDataForm from './ProjectDataForm.vue'
import ProjectObservations from './ProjectObservations.vue'
import ProjectCreditValidation from './ProjectCreditValidation.vue'
import ProjectJudicialVerification from './ProjectJudicialVerification.vue'
import ProjectAnalyst from './ProjectAnalyst'
import ProjectRestrictions from './ProjectRestrictions.vue'
import api from '../../mixins/api.js'
export default {
  mixins: [api],
  props: {
    popupShowActive: Boolean,
    project: Object,
    userInfo:Object
  },
  data (){
    return{
      colorx:"rgba(255,255,255,255)"
    }
  },
  methods: {
    goToProject(){
      this.$router.push('project')
    }
  },
  created(){

  },
  mounted(){
  },
  components:{
    ProjectHeader,
    ProjectConceptGeneral,
    ProjectDataForm,
    ProjectObservations,
    ProjectCreditValidation,
    ProjectJudicialVerification,
    ProjectAnalyst,
    ProjectRestrictions
  },
  computed:{

  }

}
</script>

<style>
  .vs-popup{
    width: 80% !important;
  }
  .button-report{
    margin-right: 1rem !important;
  }
</style>
