<template>
  <div v-if="notifications != null">
    <vs-dropdown vs-custom-content vs-trigger-click class="cursor-pointer">
      <feather-icon icon="BellIcon" class="cursor-pointer mt-1 sm:mr-6 mr-6" :badge="notifications.length"></feather-icon>
      <vs-dropdown-menu class="notification-dropdown dropdown-custom vx-navbar-dropdown">
        <div class="notification-top text-center p-5 bg-primary text-white">
          <h3 class="text-white">{{ notifications.length }} notificaciones</h3>
        </div>
        <div class="container-notifications">
          <div class="container-notification" v-for="notification in notifications" :key="notification.project.id" @click.prevent="goToNotifications(notification.project.id)">
            <div class="container-icon event-notification">
              <span data-v-2c2399b2="" class="feather-icon select-none relative"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-bell text-warning stroke-current mr-1 h-6 w-6"><path d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9"></path><path d="M13.73 21a2 2 0 0 1-3.46 0"></path></svg></span>
            </div>
            <div class="notification-content event-notification">
              <small class="text-content-notification">{{ notification.project.full_name }}</small>
              <small class="name-key-content-notification">{{ notification.last_notification.name_key }}</small>
              <div class="notification-ago">
                <small class="text-ago-notification">{{ notification.last_notification.created_at | formatDate }}</small>
              </div>
            </div>
          </div>
          <div class="container-button-view-more" v-if="notifications.length > 0">
            <p class="button-view-more" @click.prevent="page += 1"> Ver más</p>
          </div>          
        </div>
      </vs-dropdown-menu>
    </vs-dropdown>    
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import api from '../../mixins/api.js'
  import moment from 'moment'
  import 'moment/locale/es'
  moment.locale('es')
  export default {
    mixins: [api],
    data() {
      return {
        per_page: 10,
        page: 1,
        notifications: null,
        settings: {
          maxScrollbarLength: 60,
          wheelSpeed: .60,
        },        
      }
    },
    computed: {
      ...mapGetters(['userInfo']),
    },
    methods: {
      async getLastNotitications() {
        const request = await this.requestGetWithoutContent(`${this.url()}api/v1/clients/last_notifications?user_id=${this.userInfo.user.id}&per_page=${this.per_page}&page=${this.page}`, {})
        if (request.status == 200 && request.data.length > 0) this.notifications = request.data
      },
      goToNotifications (projectId) {
        this.$store.commit('changeCurrentProjectNotification', projectId)
        if (window.location.pathname != '/notifications') this.$router.push(`/notifications`)
      }
    },
    filters: {
      convertTimeStamp: function(timestamp) {
        var date = new Date(timestamp * 1000);
        return moment(date, "YYYYMMDD").fromNow();
      },
      formatDate: function(value) {
        return moment(value).format('LL h:mm:ss a')
      }            
    },
    watch: {
      page(newValue, oldValue) {
        if (newValue > oldValue) this.getLastNotitications()
      }
    },
    mounted () {
      this.getLastNotitications()
    },
  }
</script>

<style scoped>

</style>
